<template>
  <div class="canceled">
    <div class="container-lg">
      <div class="row mb-3">
        <div class="col-3 col-sm-1 mx-auto">
          <img src="@/assets/img/error.png" class="img-fluid" alt="error icon" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 mx-auto">
          <h1 class="text-center b-700">Payment Failed!</h1>
          <p class="text-center">
            Something went wrong! Please try again in a bit.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "canceled",
};
</script>

<style lang="scss" scoped>
.canceled {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 160px);
}
</style>
